* {
  box-sizing:border-box;
  margin:0;
  font-family: 'Roboto', sans-serif;
}

.App
{
  width:100vw;
  height:100vh;
  background-color:#f5f5f5;
}