.modal {
    --bs-modal-width:340px !important;
}

.modal-backdrop.show {
    --bs-backdrop-opacity: 0;
}

.head {
    background-color:#872878;
}

.btn-close {
    width:0.3em;
    height: 0.3em;
    margin-left:15px;
    margin-top:15px;
}

.title {
    margin:auto;
    margin-top:0;
    color:white;
    font-size:1.125rem;
    font-weight:400;
}

.modal-header {
    padding-top:0;
}

.modal-body {
    background-color:#fafafb;
    padding:0;
}

.desc {
    padding-left:20px;
    background-color:#d0e5e7;
    font-size:14px;
    color: #5ba9ba;

}

.confirm-button {
    background-color: #872878;
    padding:10px 0;
    color: white;
    font-weight:400;
    width:100%;
    margin-bottom:60px;
    border-radius:0;
}

.confirm-button:hover {
    background-color: #9d2d8c !important;
    color:white !important;
}

.confirm-button-disabled {
    background-color: rgba(0, 0, 0, 0.12);
    cursor:default !important;

}

.confirm-button-disabled:hover {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.12) !important;  

}

.back {
    border:none;
    width: 8px;
    height: 14px;
    margin-left: 10px;
    margin-top: 10px;
    color: white;
    background: url(../../public/images/back.png) no-repeat center;
    cursor: pointer;
}

.divBtn {
    margin-top:10px;
    width:100%;
    padding-left:20px;
    padding-right:20px;
}

.mapBtn {
    padding-top:10px;
    padding-bottom:10px; 
    width:100%;
    background-color:#872878;
    color:#ffffff;
    transition: background-color 0.3s ease;
    border:0;
    font-size:16px;
    font-weight: 700;
}

.mapBtn:hover {
    background: #b2359f;
    transition: background-color 0.3s ease;
}

.mapBtn:focus {
    background: #b2359f !important;
    transition: background-color 0.3s ease;
}

.locations {
    list-style:none;
    padding-left: 0;
    margin:0;
}

.loc-item {
    padding:10px 0px 10px 10px;
    cursor:pointer;
}

.loc-item:hover{
    background:#eee;
}

.loc-item > p {
    color: #667280;
    font-weight:700;
    font-size:16px;
    margin-top:10px;
}


.form-control:focus {
    box-shadow: none;
    background-color: transparent;
}

.vehicle-group {
    padding:25px;
}

.vehicle-input {
    color: #667281;
    font-size: 16px;
    font-weight:300;
}


.react-calendar {
    max-width:70%;
    margin:auto;
}

.react-calendar__tile--now {
    background-color: #fea800 !important;
}

.divCalendar {
    display:flex;
    justify-content:center;
    align-items:center;
    padding:20px 50px 10px 50px;
}

.calendar {
    text-align:center;
    background-color:#eeeeee;
}

.hours-mins {
    display:flex;
    justify-content:center;
    align-items:center; 
    padding:30px;
    gap:10px;
    color:#667280 !important;
}

.select-hours {
    color:#667280 !important;
    width:70px;
}

.form-group {
    margin-bottom:40px;
}

.input-group {
    display:flex;
    align-items:center;
    justify-content:center;
    gap:10px;
    background-color:rgb(255,255,255);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px 0px;

    
}



.form-group > h3 {
    font-size: 1rem;
}

.input-group > input {
    margin:0;
    border: 0px;
    height:50px;
    color:#9B9B9B !important;
    background-color: #ffffff00;
}

.icon-location {
    margin-left:20px;
    font-size: 1.72rem;
    fill: #9B9B9B !important;
    width: 1em;
    height: 1em;
}

.error-location {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px 0px;
    background-color: rgb(239, 69, 56);
}

.error-location-text {
    font-size:0.85rem;
    text-align:left;
    padding-top:10px;
    padding-bottom:10px;
    padding-left:12px;
    color:white;
}


.modal:focus {
    --bs-modal-header-border-width: 0;
}

.form-group-error {
    border-radius:5px;
    border:1px solid #ef4538;
}

.form-group-valid {
    border-radius:5px;
    border:1px solid #872878;
}

.vehicle-select {
    border:none;
    height:50px;
}

.vehicle-required {
    font-size: 1.08rem;
    color:#872878;
}

.gzAQU {
    background-color: rgb(88, 89, 91);
    height: 5px;
    max-width: 100%;
    width: 33.3333%
}


.dGmZcK {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    width: 100%;
}

.LWxtv {
    background-color: rgb(88, 89, 91);
    height: 5px;
    max-width: 100%;
    width: 66.6667%;
}

.euiWPR {
    background-color: rgb(88, 89, 91);
    height: 5px;
    max-width: 100%;
    width: 100%;
}