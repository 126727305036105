
    :root {
    --bg-color: #eee;
    --txt-color: #434c57;
    --main-color: #872878;
    --focus-color: #ac349a;
    --error-color: #d84545;
    --label-color: #949D9F;
    --input-bg: #fff;
    --input-color: var(--txt-color);
    --input-border: #ced4da;
    --input-active-border-color: var(--main-color);
    --input-placeholder-color: var(--label-color);
    --input-disabled-bg: #e9ecef;
    --border-radius: .25rem;
    --main-font: "Titillium Web", sans-serif;
    }
    *,
    *::before,
    *::after {
    box-sizing: border-box;
    }
    html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
    }
    article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
    }
    body {
    margin: 0;
    height: 100%;
    font-family: var(--main-font);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--txt-color);
    text-align: left;
    background-color: var(--bg-color);
    }
    .ttable {
    input,
    button,
    select,
    optgroup,
    textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    }
    button,
    input {
    overflow: visible;
    }
    select {
    word-wrap: normal;
    }
    input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
} 
    input[type="text"], input[type="number"],
    select {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: .75rem 1.75rem .75rem .75rem;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--input-color);
    background-color: var(--input-bg);
    background-clip: padding-box;
    border: 1px solid var(--input-border);
    border-radius: var(--border-radius);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    }
    select {
    vertical-align: middle;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
    background-color: var(--input-bg);
    }
    /* Focus */
    input[type="text"]:focus, input[type="number"]:focus,
    select:focus {
    border-color: var(--input-active-border-color);
    outline: 0;
    }
    /* Placeholders */
    input[type="text"]::-webkit-input-placeholder,input[type="number"]::-webkit-input-placeholder
    input[type="text"]::placeholder,input[type="number"]::placeholder {
    color: var(--input-placeholder-color);
    opacity: 1;
    }
    /* Buttons container */
    #CfButtonDiv { 
     display: flex; 
     flex-direction: column-reverse;
     justify-content: center;  
     margin-bottom: 1rem; 
     } 
     
    /* #CfButtonDiv {margin-top:25px;text-align:center;padding:1px;margin-bottom: 25px;} */
    
    /* Cancel button */
    .cancelLink {
        text-decoration:none;
        padding-top: 10px;
        padding-right: 45px;
        padding-bottom: 10px;
        padding-left: 45px;
        margin-right:5px;
        margin-left:5px;
        -webkit-appearance:none;
        border-radius:5px;
        background-color:#ffffff;
        color: #872878;
        font-weight: bold;
        border-style: solid;
    }
    .cancelLink:hover,
    .cancelLink:focus,
    .cancelLink:active {
    background: var(--focus-color);
    color: #fff;
    }
    /* OK button */
    .acceptLink {
        text-decoration:none;
        padding-top: 10px;
        padding-right: 45px;
        padding-bottom: 10px;
        padding-left: 45px;
        margin-right:5px;
        margin-left:5px;
        -webkit-appearance:none;
        border-radius:5px;
        background-color: #872878;
        color:#ffffff;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .acceptLink:hover,
    .acceptLink:focus,
    .acceptLink:active {
    background: var(--focus-color);
    color: #fff;
    }
    /* Buttons */
    input[type="button"],
    input[type="submit"] {
    display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    padding: .5rem;
    font-size: 1.375rem;
    font-family: var(--main-font);
    line-height: 1.5;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    }
    input[type="button"]:hover,
    input[type="button"]:focus,
    input[type="button"]:active,
    input[type="submit"]:hover,
    input[type="submit"]:focus,
    input[type="submit"]:active {
    background-color: var(--focus-color);
    border-color: var(--focus-color);
    outline: none;
    }
    input[type="button"][disabled],
    input[type="button"][disabled]:hover,
    input[type="button"][disabled]:focus,
    input[type="button"][disabled]:active,
    input[type="submit"][disabled],
    input[type="submit"][disabled]:hover,
    input[type="submit"][disabled]:focus,
    input[type="submit"][disabled]:active {
    background-color: var(--input-disabled-bg);
    color: var(--input-color);
    border-color: var(--input-border);
    outline: none;
    }
    /* container for label + input */
    #CfPanDiv,
    #CfExpDateDiv,
    #CfSecCodeDiv,
    #CfSecCodeValueDiv,
    #CfAmountDiv,
    #CfTerminalDiv,
    #CfOrderDiv,
    #CfDateDiv,
    #CfDetailsDiv {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
    text-align: left;
    }
    /* Form Labels (in order of appearance)*/
    #CfPanNameDiv,
    #CfExpDateNameDiv,
    #CfSecCodeNameDiv,
    #CfAmountNameDiv,
    #CfTerminalNameDiv,
    #CfOrderNameDiv,
    #CfDateNameDiv,
    #CfDetailsNameDiv {
    display: inline-block;
    text-transform: uppercase;
    font-size: .875rem;
    font-weight: 600;
    color: var(--label-color);
    margin-bottom: .25rem;
    text-align: left;
    }
    /* input containers */
    #CfPanValueDiv,
    #CfExpDateValueDiv,
    #CfSecCodeValueDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    }
    /* Month & Year selects */
    #CfExpDateM {
    display: inline-block;
    width: 48%;
    margin-right: 2%;
    }
    #CfExpDateY {
    display: inline-block;
    width: 46%;
    margin-left: 2%;
    }
    /* Security code input */
    #CfSecCode {
    display: inline-block;
    width: 48%;
    margin-right: 2%;
    }
    /* Security code hint */
    #CfSecCodeB {
    display: inline-block;
    margin: unset;
    margin-left: 2%;
    }
    #CfSecCodeB {
    width: 25px;
    height: 25px;
    color: #fff;
    background-color: var(--main-color);
    font-size: 16px;
    font-weight: 600;
    border-radius: 50%;
    border: none;
    padding: 0px;
    }
    /* Fake details Textarea */
    #CfDetailsValueDiv {
    width: 100%;
    height: 2.5rem;
    background-color: var(--input-disabled-bg);
    border: 1px solid var(--input-border);
    color: var(--input-color);
    overflow-y: auto;
    }
    table {
    border-collapse: collapse;
    }
    td {
    padding: 0;
    text-align: center;
    }
    ::-ms-clear {
    display: none;
    }
  }
    /* Tbl 1 */

    #CfTr1 {}
    #CfTd1A {
    background: #eee;
    }
    #CfTd1B {
    width: 1000px;
    }
    #CfTd1C {
    background: #eee;
    }
    #CfImg1A {
    display: none;
    }
    #CfImg1C {
    display: none;
    }
    /* Tbl 2 */
    #CfTbl2 {
    width: 100%;
    height: 100%;
    }
    #CfTr2A {
    display: none
    }
    #CfTr2B {}
    #CfTr2C {
    display: none;
    }
    #CfTd2A {
    border-top: 25px solid #eee;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #eee;
    }
    #CfTd2B {
    height: 100%;
    vertical-align: top;
    }
    #CfTd2C {
    background-color: #eee;
    padding: 10px;
    text-align: left;
    display: none;
    }
    #CfImg2A {
    height: 32px;
    }
    #CfImg2C {
    display: none;
    }
    #CfDiv2C {
    font-weight: normal;
    }
    /* Tbl 3 */
    #CfTbl3 {
    width: 100%;
    height: 100%;
    }
    #CfImg3A {
    display: none;
    }
    #CfImg3C {
    display: none;
    }
    /* Tbl 4 */
    #CfTbl4 {
    margin-left: auto;
    margin-right: auto;
    }
    #CfTr4A {}
    #CfTr4B {}
    #CfTr4C {}
    #CfTd4A {
    padding-top: 5px;
    padding-bottom: 5px;
    }
    #CfTd4C {
    padding: 5px
    }
    #CfImg4A {
    height: auto;
    max-width: 100%;
    }
    .Img4C {
       width: 50%;
    }
    /* Divs Hdr & Ftr */
    #CfDivHdr {
    display: none
    }
    #CfDivFtr {
    display: none
    }
    /* Div Opd */
    #CfDivOpdFtr {
    margin-top: 1rem;
    padding: 0.3125rem;
    border: 1px solid var(--txt-color);
    border-radius: var(--border-radius);
    font-size: 1rem;
    }
    /* Div Pcd */
    /* This is the form container card */
    #CfDivPcd,
    #CfDivOpd {
    display: block;
    margin: 0;
    padding: 3.125rem;
    background: #fff;
    width: 100%;
    min-width: 85%;
    border-radius: 5px;
    margin: 1rem 0;
    }
    
    /* Extra small devices (portrait phones, less than 576px) */
    /* @media (max-width: 575.98px) {*/
    @media only screen and (max-width: 650px) {
      body {
        background-color: #fff;
      }
      #CfTd3B {
        display: block;
      }
      /* remove margins on mobile */
      #CfTbl4 {
        margin: 0 auto;
        width: 90%;
      }
      #CfDivPcd,
      #CfDivOpd {
        min-width: 100%;
        padding: 0;
      }
      #CfTr4A {
        display: none;
      }
      
      .Img4A{
        padding-top: 3%;
    }
    }
    
    
    #CfDivOpd {
    display: none
    }
    /* Form title */
    #CfDivPcdHdr,
    #CfDivOpdHdr {
    font-weight: 600;
    font-size: 22px;
    margin: 0 0 1.25rem;
    }
    #CfDivPcdFtr {
    display: none
    }
    /* Popups */
    #PopupLayer1 {display:none;position:absolute;left:0px;top:0px;bottom:0;width:100%;height:100%;z-index:-1;background:#A0A0A0;opacity:0.5;}
    #PopupLayer2 {display:none;position:absolute;left:0px;top:0px;width:100%;height:100%;z-index:200;}
    .Popup {display:none;position:relative;z-index:300;top:10px;max-width:500px;margin-left:auto;margin-right:auto;padding:5px;border:2px solid #000000;border-radius:5px;background:#FFFFFF;}
    .PopupHeader {font:bold 14px arial;padding-left:10px;padding-right:10px;padding-top:5px;padding-bottom:5px;border-bottom:2px solid #000000;}
    .PopupBody   {font:normal 14px arial;padding-left:10px;padding-right:10px;padding-top:15px;padding-bottom:15px;}
    .PopupTitle  {}
    .PopupClose  {float:right;cursor:pointer;background-color:#1f497d;color:#ffffff;text-align:center;padding-left:5px;padding-right:5px;padding-bottom:1px;margin-top:-1px;}
    #SecCodePopupBody   {padding:0px;padding-bottom:5px;text-align:center;}
    .SecCodePopupBody1a {margin-left:5px;margin-left:5px;margin-right:5px;margin-top:10px;margin-bottom:0px;display:inline-block;max-width:190px;}
    .SecCodePopupBody1b {margin-left:5px;margin-left:5px;margin-right:5px;margin-top:10px;margin-top:5px;display:none;max-width:190px;}
    .SecCodePopupMsg    {margin-left:5px;font-size:12px;text-align:left;}
    #WaitPopup     {max-width:300px;}
    #WaitPopupBody {text-align:center;}
    #ConfirmPopupButtonDiv {text-align:center;margin-top:20px;}
    #ConfirmPopupButton    {border:none;border-radius:5px;height:25px;}
    
    #ConfirmPopupMsg {
    font-size: 1rem;
    margin-bottom: 1rem;
    }
    #ConfirmPopupButtonDiv {
    margin: unset;
    text-align: unset;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 1rem 0;
    border-top: 1px solid var(--input-border);
    margin-left: -1rem;
    margin-right: -1rem;
    }
    
    
    #hidCvvInfo {
    width: 25px;
    height: 25px;
    color: #ffffff;
    background-color: #872878;
    font-size: 16px;
    font-weight: bold;
    border-radius: 50%;
    border: none;
    padding: 0px;
    text-decoration: none;
    display: inline-table;
    text-align: center;
    }
     #hidCvvInfo > span {
            display: table-cell;
            vertical-align: middle;
        }
    
    #hidCardNumber {
    width: 100%;
    }
    #hidMonth {
    width: 50%;
    margin-right: 5px;
    }
    #hidYear {
    width: 50%;
    }
    #hidCvv {
    width: 50%;
    margin-right: 5px;
    }
  

  #CfTbl1 {
    width: 100%;
    height: 100%;
    }

    .send-otp-again:hover {
      cursor:pointer;
    }