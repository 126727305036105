.park-white-box {
    background-color: rgb(255,255,255);
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.park-info {
    padding-top: 30px;
    text-align:center;
}

.add-grey {
    color:#9B9B9B;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
    margin:0;
}

.info-container {
    display: flex;
    gap:15px;
    justify-content:center;
}

.info-container div {
    display:flex;
    justify-content:center;
    align-items:center;
}